<template>
  <div class='mt-4'>
    <v-chart class='chart bg-white' :option='lineAndBar' style='height: 400px; width:1300px;' />
  </div>
</template>

<script>
import { LineChart, BarChart } from 'echarts/charts'
import { use } from 'echarts/core'
use([
  LineChart, 
  BarChart, 
])
export default {
  name: 'PeriodChart',
  props: [
    'periodChartData',
  ],
  data() {
    return {
      lineAndBar: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '투자건수',
            min: 0,
            max: 30,
            interval: 15,
            axisLabel: {
              formatter: '{value}건'
            }
          },
          {
            type: 'value',
            name: '금액',
            min: 0,
            max: 200,
            interval: 100,
            axisLabel: {
              formatter: '{value} 억원'
            }
          },
          {
            type: 'value',
            name: '',
            min: 0,
            max: 200,
            interval: 100,
            axisLabel: {
              formatter: '{value} 억원'
            }
          },
        ],
        series: [
          {
            name: '투자건수',
            type: 'line',
            color: ['#52527F'],
            yAxisIndex: 0,
            tooltip: {
              valueFormatter: function (value) {
                return value + '  건';
              }
            },
            data: []
          },
          {
            name: '투자금액',
            type: 'bar',
            color: ['#3267A2'],
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 억원';
              }
            },
            data: []
          },
          {
            name: '회수금액',
            type: 'bar',
            color: ['#BE2457'],
            yAxisIndex: 2,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 억원';
              }
            },
            data: []
          },
        ]
      },
    }
  },
  watch: {
    'periodChartData.date': {
      handler: function () {
        this.refreshData ()
      },
      immediate: true
    },
  },
  methods: {
    refreshData () {
      this.lineAndBar.xAxis[0].data = this.periodChartData.date
      this.lineAndBar.yAxis[0].max = parseInt(Math.max.apply(Math, this.periodChartData.count) * 1.1)
      this.lineAndBar.yAxis[0].interval = parseInt(Math.max.apply(Math, this.periodChartData.count) / 5)
      this.lineAndBar.series[0].data = this.periodChartData.count

      let valueMax = parseInt(Math.max.apply(Math, this.periodChartData.value) * 1.1)
      let collectionMax = parseInt(Math.max.apply(Math, this.periodChartData.collection) * 1.1)
      if (collectionMax > valueMax)
        valueMax = collectionMax

      this.lineAndBar.yAxis[1].max = valueMax
      this.lineAndBar.yAxis[1].interval = parseInt(valueMax / 5)
      this.lineAndBar.series[1].data = this.periodChartData.value

      this.lineAndBar.yAxis[2].max = valueMax
      this.lineAndBar.yAxis[2].interval = parseInt(valueMax / 5)
      this.lineAndBar.series[2].data = this.periodChartData.collection
    }
  },
}
</script>
